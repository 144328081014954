import React from "react"

import Layout from "../../components/layout"
import SEO from '../../components/seo'
import { colors, md } from "../../common"

export default () => {
  
  React.useEffect(() => {
    const script = document.createElement("script")
    console.log("hi");
    script.src = "https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=482240"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
  <Layout>
    <div
      css={`
        padding: 2rem;
        text-align: center;
        ${md} {
          padding: 1rem;
        }
      `}
    >
      <SEO title="Penny Preville" />
      <div css={`padding:2rem;text-align:center;${md}{padding:2rem;}`}>
        <img css={`max-width:400px;width:100%;`} src="https://s3.amazonaws.com/underwoods/_designers/penny-preville/logo.png" alt="logo for Penny Preville" />
        <div css={`color:${colors.greyDarker};padding:2rem;line-height:24px;${md}{padding:1rem;}`}>
          Penny Preville's diamond fashion jewelry can be found at our Ponte Vedra store. Penny mixes styles which range from modern to vintage in 18kt. gold. All of Penny Preville's designs are hand crafted in New York City.
        </div>
      </div>

      <a
        href="https://online.flippingbook.com/view/482240/"
        className="fbo-embed"
        data-fbo-id="482240"
        data-fbo-lightbox="yes"
        data-fbo-width="740px"
        data-fbo-height="480px"
        data-fbo-version="1"
        style={{ maxWidth: "100%" }}
      >
        Underwood&#39;s Penny Preville Look Book Spring 2020
      </a>
    
    </div>
  </Layout>
)
      }
